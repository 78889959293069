/* eslint-disable prettier/prettier */
<template>
  <div class="home">
    <main class="s-main-bg">
      <Navbar/>
      <div class="s-ht-100-vh s-keep-view">
        <router-view></router-view>
      </div>
      <Footer/>
    </main>
  </div>
</template>

<script>
import Navbar from "@/components/main/navbar";
import Footer from "@/components/main/footer";
export default {
  name: "Home",
  components:{
    Navbar, 
    Footer
  }
};
</script>
