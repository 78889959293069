<template>
    <div id="footer">
        <div class="s-main-container s-flex s-ptb-40 s-wrap">
            <div class="s-footer-column">
                <h4>{{$t('footer.stayInTouch')}}</h4>
                <div class="s-center-flex">
                    <div class="s-square-30">
                        <img :src="require('@/assets/icons/primary/icon-phone.svg')"/>
                    </div>
                    <p>(0293)788225</p>
                </div>
                <div class="s-center-flex">
                    <div class="s-square-30">
                        <img :src="require('@/assets/icons/primary/icon-phone.svg')"/>
                    </div>
                    <p>(0293)788175</p>
                </div>
                <div class="s-center-flex">
                    <div class="s-square-30">
                        <img :src="require('@/assets/icons/primary/icon-mail.svg')"/>
                    </div>
                    <p>drmborobudur@gmail.com</p>
                </div>
            </div>
            <div class="s-footer-column">
                <h4>{{$t('footer.quickLinks')}}</h4>
                <router-link :to="$translate({name: 'Content List', params: {contentType: 'brrd'}})" class="s-text-none s-text-hover">
                    <p>{{$t('footer.contentLatestAdditions')}}</p>
                </router-link>
                <router-link :to="$translate({name: 'Feedback Form'})" class="s-text-none s-text-hover">
                    <p>{{$t('footer.giveFeedback')}}</p>
                </router-link>
                <router-link :to="$translate({name: `${isLoggedIn? 'Dashboard CMS':'authentication'}`, params: {authType: `${isLoggedIn? '':'login'}`}})" class="s-text-none s-text-hover">
                    <p>{{$t('footer.contentPublication')}}</p>
                </router-link>
                <router-link :to="$translate({name: 'Expert Matching'})" class="s-text-none s-text-hover">
                <p>{{$t('footer.expertMatching')}}</p>
                </router-link>
            </div>
            <div class="s-footer-column ">
                <h4>{{$t('footer.relativeLinks')}}</h4>
                <a href="http://borobudurpedia.id/" class="s-cursor-pointer s-text-none s-text-hover" target="_blank">
                    <p>Borobudurpedia</p>
                </a>
                <a href="https://www.instagram.com/konservasiborobudur/" class="s-cursor-pointer s-text-none s-text-hover" target="_blank">
                    <p>Balai Konservasi Borobudur</p>
                </a>
                <a href="http://kebudayaan.kemdikbud.go.id/bkborobudur/" class="s-cursor-pointer s-text-none s-text-hover" target="_blank">
                    <p>BKB Borobudur</p>
                </a>
            </div>
        </div>
        <div class="s-bg-primary s-fullWidth s-text-center  s-ptb-5">
            <p class="s-text-white">Borobudur DRM Communication Platform © 2021, Departemen Arsitektur Universitas Atma Jaya Yogyakarta</p>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters({
            isMobile: 'getIsMobile',
            isLoggedIn: 'getIsLoggedIn'
        })
    }
}
</script>